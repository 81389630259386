<template>
  <div class="boot_settings_view" v-loading="loading">
    <div class="boot_settings_view_left">
      <div class="boot_settings_view_left_bg">
        <div class="boot_settings_view_left_view" @click="isShowWindow=true">
          <el-carousel
            v-if="form.bootAdType=='img'"
            indicator-position="none"
            height="458px"
            class="boot_settings_view_left_view_carousel"
          >
            <el-carousel-item v-for="item in form.imgList" :key="item">
              <el-image style="width: 100%; height: 100%" :src="fileUrl+item" fit="fill"></el-image>
            </el-carousel-item>
          </el-carousel>
          <video
            v-else
            id="video"
            :src="fileUrl+form.bootAdVideoUrl"
            controls
            autoplay
            muted
            class="boot_settings_view_left_view_video"
          ></video>
        </div>
      </div>
    </div>
    <div style="width: 400px;" v-show="!bodySize"></div>
    <div class="boot_settings_view_right" v-show="isShowWindow">
      <div class="boot_settings_view_right_btn">
        <div>
          <i
            class="el-icon-arrow-right"
            style="font-weight:bold;cursor:pointer"
            @click="isShowWindow=!isShowWindow"
          ></i>&nbsp;广告设置
        </div>
        <el-button
          type="primary"
          round
          size="small"
          style="width:100px"
          @click="onSubmitEditAdvert"
        >保存</el-button>
      </div>

      <el-form
        :model="form"
        ref="form"
        label-position="top"
        label-width="80px"
        size="small"
        class="boot_settings_view_right_form"
      >
        <el-form-item label="展示时间">
          <el-input-number v-model="form.limitedTime" :min="0"></el-input-number>&nbsp;&nbsp;&nbsp;秒
        </el-form-item>
        <el-form-item label="选择类型">
          <el-radio v-model="form.bootAdType" label="img">图片</el-radio>
          <el-radio v-model="form.bootAdType" label="video">视频</el-radio>
        </el-form-item>
        <el-form-item
          label="背景图片"
          class="boot_settings_view_right_img_card"
          v-if="form.bootAdType=='img'"
        >
          <el-row :gutter="0">
            <el-col
              :xl="12"
              :lg="12"
              :md="12"
              :sm="24"
              :xs="24"
              :offset="0"
              v-for="(item,index) in form.imgList"
              :key="index"
              style="margin-bottom:10px;"
            >
              <div class="boot_settings_view_right_img">
                <el-image style="width: 100px; height: 100px" :src="fileUrl+item" fit="contain"></el-image>
                <label @click="delEditImgs(index)" class="delImgIcon">
                  <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF;"></i>
                </label>
              </div>
            </el-col>
            <el-col
              :xl="12"
              :lg="12"
              :md="12"
              :sm="24"
              :xs="24"
              :offset="0"
              style="margin-bottom:10px;"
              v-if="form.imgList.length<9"
            >
              <el-dropdown placement="top">
                <div class="addImg_card">
                  <i class="el-icon-plus addImg_card_icon"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="dialogEditImgFileUpload=true"
                    style="padding:0"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                  <el-dropdown-item style="padding:0">
                    <el-upload
                      style="text-align:center"
                      :action="actionUrl"
                      accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                      :headers="header"
                      :data="{type:0,category:'图片'}"
                      :show-file-list="false"
                      :on-success="handleEditImgSuccess"
                      :before-upload="beforeEditImgUpload"
                      multiple
                      :limit="isMaxImgCount"
                    >本地上传</el-upload>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
          <span>多个图以轮播形式展示最多9个</span>
        </el-form-item>

        <el-form-item label="背景视频" v-else>
          <el-dropdown placement="top">
            <div>
              <div class="addVideo_card" v-if="!form.bootAdVideoUrl">
                <i class="el-icon-plus addVideo_card_icon"></i>
              </div>
              <div class="upload_video_address" v-else>
                <label @click="delEditVideo(index)" class="delVideoIcon">
                  <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF;"></i>
                </label>
                <div>{{form.bootAdVideoUrl}}</div>&nbsp;
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="dialogEditVideoFileUpload=true"
                style="padding:0"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
              <el-dropdown-item style="padding:0">
                <el-upload
                  style="text-align:center"
                  :action="actionUrl"
                  accept=".mp4, .flv, .ts, .MP4, .FLV, .TS"
                  :headers="header"
                  :data="{type:1,category:'背景视频'}"
                  :show-file-list="false"
                  :on-success="handleEditVideoSuccess"
                  :before-upload="beforeEditVideoUpload"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </el-form>
    </div>

    <!-- 图片 -->
    <el-dialog
      :visible.sync="dialogEditImgFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload
        :choice="false"
        :type="0"
        :count="this.isMaxImgCount"
        :category="'图片'"
        v-if="dialogEditImgFileUpload"
      ></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditImgFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditImg" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 视频 -->
    <el-dialog :visible.sync="dialogEditVideoFileUpload" :close-on-click-modal="false" width="55%">
      <span slot="title">资源库</span>
      <FileUploadVideo
        :type="1"
        :category="'背景视频'"
        v-on:callBackPath="onSubmitEditVideoUrl"
        v-if="dialogEditVideoFileUpload"
      ></FileUploadVideo>
    </el-dialog>
  </div>
</template>
<script>
import { getBootSettingApi, editBootSettingApi } from "../../api/data";
import FileUpload from "../../components/FileUpload";
import FileUploadVideo from "../../components/FileUploadVideo";
import Aes from "../../utils/aes";
export default {
  components: {
    FileUpload,
    FileUploadVideo
  },
  data() {
    return {
      loading: true,
      form: {
        bootAdId: "",
        bootAdType: "img",
        bootAdImgUrl: "",
        bootAdVideoUrl: "",
        groupId: "",
        limitedTime: "10",
        imgList: []
      },
      dialogEditImgFileUpload: false, //图片资源库
      dialogEditVideoFileUpload: false, //视频资源库
      bodySize: false, //当前窗口是否小于1024
      isShowWindow: true //是否显示右侧操作窗口
    };
  },
  computed: {
    //最大上传图片数
    isMaxImgCount() {
      return 9 - this.form.imgList.length;
    },
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    }
  },
  created() {
    this.initLoad();
    this.wathSize();
  },
  methods: {
    //初始化开机界面
    initLoad() {
      getBootSettingApi({
        groupId: this.placesInfo.groupId
      }).then(res => {
        if (res) {
          this.loading = false;
          this.form.bootAdId = res.data.bootAdId;
          this.form.bootAdType = res.data.bootAdType;
          this.form.imgList = res.data.imgList;
          this.form.bootAdVideoUrl = res.data.bootAdVideoUrl;
          this.form.groupId = res.data.groupId;
          this.form.limitedTime = res.data.limitedTime;
        }
      });
    },
    //提交编辑
    onSubmitEditAdvert() {
      this.form.groupId = this.placesInfo.groupId;
      editBootSettingApi(this.form).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //上传图片完成事件
    handleEditImgSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.form.imgList.push(result.msg);
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传图片前事件
    beforeEditImgUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库图片添加完成事件
    onSubmitEditImg() {
      this.dialogEditImgFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        for (let i = 0; i < this.$store.state.fileList.length; i++) {
          this.form.imgList.push(this.$store.state.fileList[i].filePath);
        }
        this.$store.commit("setFileList", []);
      }
    },
    //图片窗口关闭事件
    closeDialogEditImgFileUpload() {
      this.$store.commit("setFileList", []);
    },
    //删除图片集合
    delEditImgs(index) {
      this.form.imgList.splice(index, 1);
    },
    //上传视频完成事件
    handleEditVideoSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.form.bootAdVideoUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传视频前事件
    beforeEditVideoUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!isLt5M) {
        this.$message.warning("上传视频大小不能超过 50MB!");
      }
      return isLt5M;
    },
    //资源库视频添加完成事件
    onSubmitEditVideoUrl(path) {
      this.dialogEditVideoFileUpload = false;
      this.form.bootAdVideoUrl = path;
    },
    //删除视频
    delEditVideo() {
      this.form.bootAdVideoUrl = "";
      let video = document.getElementById("video");
      video.src = "";
    },
    //监听当前窗口大小调整占用右侧DIV
    wathSize() {
      if (document.body.clientWidth <= 1024) {
        this.bodySize = true;
      } else {
        this.bodySize = false;
      }
      window.addEventListener("resize", () => {
        if (document.body.clientWidth <= 1024) {
          this.bodySize = true;
        } else {
          this.bodySize = false;
        }
      });
    }
  }
};
</script>
<style lang='scss'>
.boot_settings_view {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: hidden;
}
.boot_settings_view_left {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .boot_settings_view_left_bg {
    background: url("../../assets/hotel_interface.png");
    background-size: cover;
    width: 100%;
    max-width: 810px;
    height: 532px;
    padding-left: 5px;
    padding-top: 4px;
  }
  .boot_settings_view_left_view {
    width: 100%;
    max-width: 802px;
    height: 458px;
    // border: 2px solid #000000;
    // border-radius: 6px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    .boot_settings_view_left_view_carousel {
      z-index: 1;
      // border-radius: 4px;
    }
    .boot_settings_view_left_view_video {
      object-fit: fill;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

.boot_settings_view_right {
  background: #ffffff;
  border-top: 1px solid #d7dae2;
  width: 20%;
  height: 100%;
  z-index: 9;
  min-width: 300px;
  position: absolute;
  top: 0;
  right: 0;
  .el-form-item__label {
    color: #9da3ac;
  }

  .boot_settings_view_right_form {
    padding: 20px;
    overflow-y: scroll;
    height: calc(100vh - 120px);
    width: 100%;
  }
  .boot_settings_view_right_form::-webkit-scrollbar {
    width: 6px;
  }
  .boot_settings_view_right_form:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .boot_settings_view_right_form::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
  .boot_settings_view_right_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    border-bottom: 1px solid #d7dae2;
    padding: 10px;
  }

  .boot_settings_view_right_img_card {
    .boot_settings_view_right_img {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      width: 100px;
      height: 100px;
      background: rgba(235, 235, 235, 0.5);
      border-radius: 6px;
      border: 1px dashed #d9d9d9;
    }
    .delImgIcon {
      width: 50px;
      height: 30px;
      background: #f56c6c;
      transform: rotate(45deg);
      position: absolute;
      right: -20px;
      top: -8px;
      text-align: center;
      padding-top: 5px;
      box-shadow: 0 1px 1px #ccc;
      cursor: pointer;
    }
    .addImg_card {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 100px;
      height: 100px;
      .addImg_card_icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
      }
    }
    .addImg_card:hover {
      border-color: #5e72f9;
    }
  }

  .addVideo_card {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 200px;
    height: 100px;
    text-align: center;
    .addVideo_card_icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
    }
  }
  .addVideo_card:hover {
    border-color: #5e72f9;
  }

  .upload_video_address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    height: 100px;
    overflow: hidden;
    position: relative;
    span {
      width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .delVideoIcon {
      width: 50px;
      height: 30px;
      background: #f56c6c;
      transform: rotate(45deg);
      position: absolute;
      right: -17px;
      top: -7px;
      text-align: center;
      padding-top: 4px;
      box-shadow: 0 1px 1px #ccc;
      opacity: 1;
      cursor: pointer;
    }
  }
}
</style>